import {
    startListenClickToSticker,
    onStickerClick,
} from '../stickers/components/StickerPackDialog/handlers';
import { initMetricsListener } from '../common/helpers/metrics';
import { initAttachments } from '../attachment/initializer';

document.addEventListener('DOMContentLoaded', () => {
    startListenClickToSticker(onStickerClick);
    initMetricsListener();
    initAttachments();
});
