import { isMobile } from '@/common/mediaQueries';

export const STICKER_FETCH_ALL = 'STICKER_FETCH_ALL';
export const STICKER_FETCH_ALL_SUCCESS = 'STICKER_FETCH_ALL_SUCCESS';
export const STICKER_FETCH_ALL_ERROR = 'STICKER_FETCH_ALL_ERROR';
export const STICKER_PACK_CREATE = 'STICKER_PACK_CREATE';
export const STICKER_PACK_CREATE_SUCCESS = 'STICKER_PACK_CREATE_SUCCESS';
export const STICKER_PACK_CREATE_ERROR = 'STICKER_PACK_CREATE_ERROR';
export const STICKER_PACK_DELETE = 'STICKER_PACK_DELETE';
export const STICKER_PACK_DELETE_SUCCESS = 'STICKER_PACK_DELETE_SUCCESS';
export const STICKER_PACK_DELETE_ERROR = 'STICKER_PACK_DELETE_ERROR';
export const STICKER_PACK_PATCH = 'STICKER_PACK_PATCH';
export const STICKER_PACK_PATCH_SUCCESS = 'STICKER_PACK_PATCH_SUCCESS';
export const STICKER_PACK_PATCH_ERROR = 'STICKER_PACK_PATCH_ERROR';
export const STICKER_PACK_ADD_OR_DELETE = 'STICKER_PACK_ADD_OR_DELETE';
export const STICKER_PACK_ADD_OR_DELETE_SUCCESS = 'STICKER_PACK_ADD_OR_DELETE_SUCCESS';
export const STICKER_PACK_ADD_OR_DELETE_ERROR = 'STICKER_PACK_ADD_OR_DELETE_ERROR';
export const STICKER_LIST_PACK_ADD_OR_DELETE = 'STICKER_LIST_PACK_ADD_OR_DELETE';
export const STICKER_LIST_PACK_ADD_OR_DELETE_SUCCESS = 'STICKER_LIST_PACK_ADD_OR_DELETE_SUCCESS';
export const STICKER_LIST_PACK_ADD_OR_DELETE_ERROR = 'STICKER_LIST_PACK_ADD_OR_DELETE_ERROR';
export const STICKER_PACK_FETCH = 'STICKER_PACK_FETCH';
export const STICKER_PACK_FETCH_SUCCESS = 'STICKER_PACK_FETCH_SUCCESS';
export const STICKER_PACK_FETCH_ERROR = 'STICKER_PACK_FETCH_ERROR';
export const STICKER_UPLOAD_NEW = 'STICKER_UPLOAD_NEW';
export const STICKER_UPLOAD_NEW_SUCCESS = 'STICKER_UPLOAD_NEW_SUCCESS';
export const STICKER_UPLOAD_NEW_ERROR = 'STICKER_UPLOAD_NEW_ERROR';
export const STICKER_DELETE = 'STICKER_DELETE';
export const STICKER_DELETE_SUCCESS = 'STICKER_DELETE_SUCCESS';
export const STICKER_DELETE_ERROR = 'STICKER_DELETE_ERROR';
export const STICKER_KEYWORD_CREATE = 'STICKER_KEYWORD_CREATE';
export const STICKER_KEYWORD_CREATE_SUCCESS = 'STICKER_KEYWORD_CREATE_SUCCESS';
export const STICKER_KEYWORD_CREATE_ERROR = 'STICKER_KEYWORD_CREATE_ERROR';
export const STICKER_KEYWORD_DELETE = 'STICKER_KEYWORD_DELETE';
export const STICKER_KEYWORD_DELETE_SUCCESS = 'STICKER_KEYWORD_DELETE_SUCCESS';
export const STICKER_KEYWORD_DELETE_ERROR = 'STICKER_KEYWORD_DELETE_ERROR';
export const STICKER_ASYNC_UPDATE = 'STICKER_ASYNC_UPDATE';
export const STICKER_LIST_STICKER_ASYNC_UPDATE = 'STICKER_LIST_STICKER_ASYNC_UPDATE';
export const STICKER_PACK_NAME_MAX_LENGTH = 64;

export const toggleStyle = {
    color: 'rgb(127, 129, 145)',
    marginRight: isMobile ? 0 : 10,
    fontSize: '13px',
};
