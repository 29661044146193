import $ from 'jquery';
import * as Sentry from '@sentry/browser';

export const initAttachments = () => {
    $(document).on('mouseleave', 'img.thumbnail-attachment-image', function onMouseLeave() {
        const $this = $(this);
        const thumbnailSrc = $this.data('thumbnail-src');
        if (thumbnailSrc) { $this.attr('src', thumbnailSrc); }
    });

    $(document).on('mouseenter', 'img.thumbnail-attachment-image', function onMouseEnter() {
        const $this = $(this);
        const pattern = /\.gif/;
        if ($this.data('attachment-image-id') && pattern.test($this.attr('src'))) {
            $this
                .css('cursor', 'default')
                .data('thumbnail-src', $this.attr('src'))
                .attr('src', `/attachments/image/${$this.data('attachment-image-id')}/`);
        }
    });

    const zoomableImages = ''
        + '.timeline-record-content-container img[src^="/media/uploads/"], '
        + '.post_content_container img[src^="/media/uploads/"], '
        + '.thumbnail-attachment-image:not(.redactor-layer *)';

    // used in blog post
    $(document).on('click', zoomableImages, async (event) => {
        $(zoomableImages).each(function onImageClick() {
            $(this).attr('data-lightbox', 'attachment-images');
        });
        const $targetEl = $(event.target);
        const parent = $targetEl.closest('.js-post_content_container');
        let items = [];
        if (parent && parent.length) {
            parent.find('img.thumbnail-attachment-image, [data-lightbox]').each(function pushImage() {
                items.push($(this).attr('src'));
            });
        } else {
            const elements = document.querySelectorAll('[data-lightbox]');
            items = [...elements].map(item => $(item).attr('src') || $(item).data('image-url'));
        }
        const startIndex = items.indexOf($targetEl.attr('src') || $targetEl.data('image-url'));
        if (startIndex >= 0) {
            const images = items.map(item => ({ url: item }));
            const eventForReact = new CustomEvent(
                'openEntryAttachmentCarousel', { detail: { startIndex, images } },
            );
            window.dispatchEvent(eventForReact);
        } else {
            Sentry.withScope((scope) => {
                scope.setExtra('startIndex', startIndex);
                scope.setExtra('items', items);
                Sentry.captureMessage('Wrong image carousel start index');
            });
        }
    });
};
