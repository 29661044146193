import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { render } from '../../../common/helpers/render';
import StickerPackDialog from './index';

const dialogElElementId = 'sticker_pack_dialog';

export function startListenClickToSticker(onClickCb = () => {}) {
    // handle all clicks
    document.addEventListener('click', (event) => {
        const target = event.target;

        // classname check
        if (!target.classList.contains('sticker-image')) {
            return;
        }

        const linkEl = target.parentElement;
        const stickerLinkRegExp = /\/stickers\/[1-9]{1}[0-9]*\//;

        // link check
        if (
            linkEl.tagName.toLocaleLowerCase() !== 'a'
            || !(linkEl.href && linkEl.href.search(stickerLinkRegExp) + 1)
        ) {
            return;
        }

        const foundSubStrArr = linkEl.href.match(stickerLinkRegExp); // ['/stickers/xxx/']
        const foundSubStr = foundSubStrArr[0];
        const stickerPackId = +foundSubStr.split('/')[2];

        event.stopPropagation();
        event.preventDefault();

        onClickCb(stickerPackId);
    }, true); // event capture
}

function getStickerPackDialogElement() {
    let dialogEl = document.getElementById(dialogElElementId);
    if (!dialogEl) {
        dialogEl = document.createElement('div');
        dialogEl.id = dialogElElementId;
        document.body.appendChild(dialogEl);
        dialogEl = document.getElementById(dialogElElementId);
    }
    return dialogEl;
}

function handleStickerPackDialogClose() {
    const dialogEl = getStickerPackDialogElement();
    unmountComponentAtNode(dialogEl);
}

export function onStickerClick(packId) {
    const dialogEl = getStickerPackDialogElement();
    render(
        <StickerPackDialog
            packId={ packId }
            handleDialogClose={ handleStickerPackDialogClose }
        />,
        dialogEl,
    );
}
