import React from 'react';
import {
    A, Dialog, Toggle, UserInfo,
} from 'intdev-ui';
import PropTypes from 'prop-types';
import { showWarningNotification } from '@/common/helpers/showNotification';
import { isMobile } from '@/common/mediaQueries';
import urls from '../../urls';
import { callApi } from '../../../common/middlewares/apiMiddleware';
import StickerPreloader from '../StickerPreloader';
import { objCamelFromSnake } from '../../../common/helpers/objCamelFromSnake';
import UserTooltipContainer from '../../../common/components/UserTooltipContainer';
import pluralized from '../../../common/helpers/pluralized';
import MrgIcon from '../../../common/components/MrgIcon';
import { toggleStyle } from '@/stickers/constants';
import { dictionary } from '@/common/localization/dictionary';

const waitingTimeout = 500;

const contentStyle = {
    width: !isMobile ? '90vw' : '100vw',
    maxWidth: '1100px',
    maxHeight: '100vh',
};
const bodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
    borderTop: 'solid rgba(0,0,0,.1) 1px',
};
const titleStyle = {
    padding: '0 20px',
};

export default class StickerPackDialog extends React.Component {
    static propTypes = {
        packId: PropTypes.number.isRequired,
        handleDialogClose: PropTypes.func.isRequired,
    };

    state = {
        pack: {},
        fetching: true,
        waiting: true, // чтобы понять дииалог был открыт недавно или нет (чтобы не показывать для удаленных)
    };

    componentDidMount() {
        setTimeout(() => this.setState({ waiting: false }), waitingTimeout);
        callApi(urls.packEntryUrl(this.props.packId))
            .then((data) => {
                this.setState({
                    pack: objCamelFromSnake(data.pack),
                    fetching: false,
                });
            })
            .catch((err) => {
                if (err.status === 404) {
                    showWarningNotification('Стикерпак удален');
                    this.props.handleDialogClose();
                }
            });
    }

    addStickerPack = () => {
        this.setState(state => ({
            pack: { ...state.pack, isAdded: true },
        }));
        callApi(urls.packAdd(this.props.packId), 'POST')
            .then((data) => {
                this.setState(state => ({
                    pack: { ...state.pack, ...objCamelFromSnake(data.pack) },
                    fetching: false,
                }));
            });
    };

    deleteStickerPack = () => {
        this.setState(state => ({
            pack: { ...state.pack, isAdded: false },
        }));
        callApi(urls.packDelete(this.props.packId), 'POST')
            .then((data) => {
                this.setState(state => ({
                    pack: { ...state.pack, ...objCamelFromSnake(data.pack) },
                    fetching: false,
                }));
            });
    };

    renderStickers = () => (
        <div className="sticker_pack_dialog__sticker_wrapper">
            {
                this.state.pack.stickers
                    .filter(sticker => sticker.url)
                    .map(sticker => (
                        <img
                            className="sticker_pack_dialog__item_sticker"
                            key={ sticker.id }
                            src={ sticker.url }
                            alt="Стикер"
                        />
                    ))
            }
        </div>
    );

    renderTitle = () => {
        const {
            fetching,
            pack: {
                id,
                isAdded,
                name,
                owner,
                installs,
                telegramUrl,
            },
        } = this.state;

        return (
            <div className="sticker_pack_dialog__title_wrapper">
                <div>
                    <span>
                        <A
                            href={ urls.packPage(id) }
                            onClick={ this.props.handleDialogClose }
                            style={ { color: '#000' } }
                        >
                            { name || '' }
                        </A>
                        {
                            telegramUrl && (
                                <A href={ telegramUrl }>
                                    <MrgIcon className="icon_social--telegram sticker_pack_dialog__pack_actions" />
                                </A>
                            )
                        }
                        {
                            installs
                        && (
                            <span className="sticker_pack_dialog__pack_installs">
                                (добавлен
                                {' '}
                                { installs }
                                {' '}
                                { pluralized(installs, 'раз:раз:раза')}
                                )
                            </span>
                        )
                        }
                    </span>
                    <div className="sticker_pack_dialog__owner_info">
                        {
                            owner
                        && (
                            <>
                                <div>{ dictionary.uploadedBy }</div>
                                <A href={ owner.url }>
                                    <UserTooltipContainer
                                        username={ owner.username }
                                    >
                                        <UserInfo
                                            avatar={ owner.avatar }
                                            fullname={ owner.fullname }
                                            avatarSize={ 20 }
                                        />
                                    </UserTooltipContainer>
                                </A>
                            </>
                        )
                        }
                    </div>
                </div>
                {
                    !fetching
                        ? (
                            <Toggle
                                isOn={ !!isAdded }
                                label={ (
                                    <span
                                        onClick={ () => (
                                            isAdded
                                                ? this.deleteStickerPack()
                                                : this.addStickerPack()
                                        ) }
                                    >
                                        { isAdded ? dictionary.added : dictionary.addTo }
                                    </span>
                                ) }
                                onClick={ () => (
                                    isAdded
                                        ? this.deleteStickerPack()
                                        : this.addStickerPack()
                                ) }
                                size={ 20 }
                                rootStyle={ toggleStyle }
                            />
                        )
                        : null
                }
            </div>
        );
    };

    render() {
        const {
            handleDialogClose,
        } = this.props;

        const {
            fetching,
            waiting,
        } = this.state;

        return (
            <Dialog
                open={ !fetching || (fetching && !waiting) }
                onRequestClose={ handleDialogClose }
                title={ this.renderTitle() }
                contentStyle={ contentStyle }
                hideCloseButton
                bodyStyle={ bodyStyle }
                titleStyle={ titleStyle }
            >
                {
                    fetching
                        ? <StickerPreloader />
                        : this.renderStickers()
                }
            </Dialog>
        );
    }
}
